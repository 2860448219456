/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


html,
body {
  height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  font-size: 15px;
}

:root {
  --orange_color_dg: #F39616;
  --fc-light: #F0F0E9;
  --fc-gray-600: #5c6c75;
}

.list {
  text-align: left;
  max-width: 750px;
  margin-bottom: 1rem;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.headerSearch {
  border-radius: 5px;
  height: 50px;
  padding: 5px;
  position: relative;
  width: 100% !important;
}


.homeBannerSection {
  position: relative;
}

.homeBannerSection .swiper .item {
  overflow: hidden;
  border-radius: 10px;
}

.homeBannerSection * {
  outline: none !important;
}

.swiper-button-next {
  right: 3% !important;
  position: absolute;
  top: 45%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px !important;
  height: 45px !important;
  background: #fff;
  border-radius: 100%;
  font-family: FontAwesome !important;
  font-weight: bold;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}

.swiper-button-prev {
  left: 3% !important;
  position: absolute;
  top: 45%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px !important;
  height: 45px !important;
  background: #fff;
  border-radius: 100%;
  font-family: FontAwesome !important;
  font-weight: bold;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #000 !important;
  opacity: 0.8;
  font-size: 17px !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: #e7e7e7 !important;
}

.homeBannerSection {
  cursor: pointer;
}

.container_pro {
  /*max-width: 800px;*/
  margin: 0 auto;
  /*padding: 20px;*/
  margin-top: 1.5rem !important;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.productItem {
  width: 23%;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 0px;
  cursor: pointer;
  position: relative;
}

@media only screen and (min-width: 350px) and (max-width:600px) {
  .container_pro {
    gap: 7px !important
  }

  .productItem {
    width: 48% !important
  }

  .productItem .img_rapper {
    height: 205px !important
  }
}

@media only screen and (min-width: 600px) and (max-width:767px) {
  .container_pro {
    gap: 7px !important
  }

  .productItem {
    width: 32.5% !important
  }
}

@media only screen and (min-width: 767px) and (max-width:992px) {
  .container_pro {
    gap: 7px !important
  }

  .productItem {
    width: 23% !important
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .productDetails .actions_ {
      align-items: center;
      display: flex;
      flex-direction: column;
  }
  .quantityDrop {
    width: 100% !important;
  }
  .btnActions {
    padding-top: 15px;
    position: relative;
    width: 100% !important;
  }
}

.productItem .info {
  padding: 15px;
}

.productItem .badge {
  display: inline-block;
  padding: 7px 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}

.productItem img {
  max-width: 100%;
  height: auto;
}

.productItem .info h4 {
  color: #2e2e2e !important;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 600;
}

.productItem .info .oldPrice {
  font-size: 16px !important;
  font-weight: bold;
  margin-left: 5px;
}

.productItem .info .newPrice {
  font-size: 16px !important;
  font-weight: bold;
}

.productItem .actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 20px;
  transition: all .5s ease-in-out;
  width: 50px;
  z-index: 1000;
}

.productItem .actions button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  border: 1px solid rgb(0, 0, 0, 0.1) !important;
  border-radius: 50% !important;
  background: #fff !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
  transition: all 0.0s !important;
}

.productItem .actions button svg {
  color: #000 !important;
  opacity: 0.8;
  pointer-events: none;
  font-size: 15px;
}

.productItem .actions button:hover {
  background: #ed174a !important;
  border: 1px solid #ed174a !important;
}

.productItem .actions button:hover svg {
  color: #fff !important;
}

.productItem .imgWrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 220px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: #fff;
}

.productItem .imgWrapper img {
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 100%;
  object-fit: cover;
}

.productItem .imgWrapper:hover .actions {
  opacity: 1;
  right: 10px;
}

.productItem .imgWrapper:hover img {
  transform: scale(1.1);
}

.badge-primary {
  background: #2bbef9 !important;
}

.badge {
  display: inline-block;
  padding: 7px 10px;
  border-radius: 20px;
}

.badge.bg-success {
  background: #e5f8ed !important;
  color: #16b858 !important;
}

.badge-success {
  color: #00b853;
  background: #e5f8ed;
}

.btn-blue {
  background: var(--orange_color_dg) !important;
  color: #fff !important;
  font-weight: 600 !important;
  padding: 5px 20px !important;
}

.btntv {
  background-color: var(--orange_color_dg) !important;
  border-color: var(--orange_color_dg) !important;
}

.text-orange {
  color: var(--orange_color_dg)
}

.btn-big {
  font-size: 15px !important;
  padding: 8px 30px !important;
  min-width: auto !important;
  font-weight: bold !important;
}

.btn-round {
  border-radius: 30px !important;
}

header .circle {
  align-items: center;
  justify-content: center;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #000;
  background: #ededed;
}

header .circle svg {
  font-size: 20px;
  color: rgb(96, 96, 96);
}

header .count {
  position: absolute;
  top: -6px;
  right: -4px;
  background: var(--orange_color_dg);
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
}

.btn-circle {
  border-radius: 50% !important;
  min-width: auto !important;
}

.info .oldPrice {
  font-size: 22px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: line-through;
  margin-left: 5px;
}

.info .newPrice {
  font-size: 22px !important;
  font-weight: bold;
}

/*PRODUCTOS*/
.productZoom {
  width: 100%;
  height: auto;
  padding: 0px;
  overflow: hidden;
  border-radius: 8px;
}

.productZoom .badge {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}

.zoomSliderBig {
  width: 100% !important;
}

.productZoomBig {
  height: 350px;
  overflow: hidden;
}

.productDetails .productZoomBig {
  height: 350px !important;
}

.zoomSlider {
  padding-top: 30px;
}

.zoomSlider * {
  outline: none !important;
}

.zoomSlider .item {
  border: 2px solid transparent !important;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  height: 70px !important;
}

.zoomSlider .item_active {
  border: 2px solid rgba(0, 0, 0, 0.3) !important;
}

.zoomSlider .item img {
  height: 100%;
  object-fit: cover;
}

.zoomSlider .swiper-button-prev {
  width: 30px !important;
  height: 30px !important;
  top: 60% !important;
  left: -3% !important;
}

.zoomSlider .swiper-button-next {
  width: 30px !important;
  height: 30px !important;
  top: 60% !important;
  right: -3% !important;
}

.zoomSlider .swiper-slide .item {
  opacity: 0.5;
}

.zoomSlider .swiper-slide .item_active {
  opacity: 1 !important;
}

.quantityDrop {
  width: 150px;
  gap: 10px;
}

.quantityDrop button {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #edeef5 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #000;
}

.quantityDrop button svg {
  color: #000 !important;
}

.quantityDrop button:hover {
  background: #ccc !important;
}

.quantityDrop input {
  width: 30px;
  border: 0px;
  background: transparent;
  outline: none !important;
  text-align: center;
}

/*MENU*/
.walsh-float-menu {
  border-bottom: 1px solid #606060;
  padding: 5px 0;
  height: auto;
  width: 100%;
}

@media only screen and (min-width: 320px) and (max-width:992px) {
  .logo {
    margin: auto;
  }

  .headerSearchWrapper {
    width: 100% !important;
  }
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

/*COLOR DE BOTONES*/
.bg_color_bt {
  background-color: var(--orange_color_dg) !important;
  color: white !important;
}

.navbar-toggler {
  border: 0 !important;
}

.text-muted-style {
  --bs-text-opacity: 1;
  color: #b8b8b8 !important;
}

.text-right {
  text-align: right !important;
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important;
}

.img_cat {
  width: 164px;
}

.img_cat_drog {
  opacity: 1;
  width: 3960px;
  transform: translate3d(-360px, 0px, 0px);
}

.card_size {
  width: 18rem;
}

.mb-lg-10 {
  margin-bottom: 3rem !important;
}

.mt-lg-14 {
  margin-top: 5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.cursor {
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  color: var(--orange_color_dg) !important;
}

.card-product {
  
}

.card-product:hover {
  border: 1px solid var(--orange_color_dg);
  box-shadow: 0 5px 12px rgba(0, 0, 0, .12);
  transition: .2s;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  -o-transition: .2s
}

.slick-slide>div {
  margin: 0 7px;
}

.slick-list {
  margin: 0 -7px;
}

.slick-slider img {
  display: inline-block !important;
  width: 60%;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-8 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important
}

.mpy-4 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.mpy-3 {
  padding-bottom: .75rem !important;
  padding-top: .75rem !important;
}

.mpx-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mbt-5 {
  margin-bottom: 1.25rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.headerSearchWrapper {
  position: relative;
  width: 58%;
}

.sidebar .filterBox {
  margin-bottom: 35px;
}

.sidebar .scroll {
  padding-left: 8px;
}

.sidebar .scroll ul {
  padding-left: 0px !important;
}

.sidebar .scroll ul li {
  list-style: none;
  margin-bottom: 0px;
  width: 100%;
}

.css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root {
  padding: 2px !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.section {
  padding: 45px 0px;
}

.productDetails .hd {
  font-size: 26px;
}

.productDetails .oldPrice {
  font-size: 20px;
}

.productDetails .newPrice {
  font-size: 20px;
}

.productSize {
  width: 100%;
  height: auto;
  padding: 25px 0px;
}

.productSize ul li {
  margin-right: 10px !important;
}

.productSize ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none !important;
  color: #000;
  font-size: 14px !important;
  transition: all 0.1s ease-in-out;
  border-radius: 4px !important;
}

.productSize ul li a:hover {
  background: #ececec;
}

.productSize ul li a.active {
  background: #6d4aae !important;
  color: #fff !important;
}

.productDetails .btn-circle {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #edeef5 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #000;
  padding: 5px !important;
}

.productDetails .btn-circle svg {
  color: #000 !important;
  opacity: 0.8;
  font-size: 18px;
}

.productDetails .btn-circle:hover {
  background: #ccc !important;
}

.loading {
  width: 100%;
  height: 300px;
}


.cartPage .table thead {
  background: #e3e3e3 !important;
  overflow: hidden;
}

.cartPage .table thead th {
  border: 0px !important;
}

.cartPage .table thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.cartPage .table thead th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.cartItemimgWrapper {
  width: 280px;
}

.cartItemimgWrapper .imgWrapper {
  width: 20%;
  height: 80px;
  overflow: hidden;
}

.cartItemimgWrapper .imgWrapper img {
  width: 100%;
  height: 100%;
}

.cartItemimgWrapper .info a:hover {
  text-decoration: none;
}

.cartItemimgWrapper .info h6 {
  color: #343434;
  font-weight: bold;
}

.cartItemimgWrapper .info h6:hover {
  color: var(--orange_color_dg) !important;
}

.remove {
  cursor: pointer;
}

.remove svg {
  font-size: 30px;
}

.remove svg:hover {
  color: var(--orange_color_dg) !important;
}

.cartPage .quantityDrop button {
  zoom: 75%;
}

.cartPage table th,
.cartPage table td {
  vertical-align: middle;
}

.cartPage .table td,
.cartPage .table th {
  border-top: 0px !important;
  border-bottom: 1px solid rgb(0, 0, 0, 0.1) !important;
}


.cartPage table tbody tr:hover {
  background: #f1f1f1;
}

.cartDetails {
  border-radius: 7px !important;
  position: sticky !important;
  top: 20px !important
}

.cartDetails h4 {
  font-size: 16px;
  padding-bottom: 15px;
  font-weight: 800 !important;
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.cartDetails span {}

.font-weight-bold {
  font-weight: 700;
}

.nav-lb-tab {
  border-bottom: 1px solid #dfe2e1;
}

.nav-lb-tab .nav-item .nav-link.active,
.nav-lb-tab .nav-item .nav-link:hover {
  background-color: transparent;
  border-bottom: 2px solid var(--orange_color_dg);
  color: var(--orange_color_dg) !important;
}

.nav-lb-tab .nav-item .nav-link {
  padding: 16px 0;
  color: var(--fc-gray-600) !important;
}

.nav-lb-tab .nav-item {
  margin-right: 2rem;
}

.btnTab {
  border-radius: 0px !important;
}



@media (min-width: 992px) {
  .offcanvas-collapse {
    background-color: transparent;
    bottom: auto !important;
    display: block;
    height: auto !important;
    left: auto !important;
    position: static !important;
    right: auto !important;
    top: auto !important;
    transform: none !important;
    visibility: visible !important;
    width: 100% !important;
    border-right: transparent !important;
  }
}

a,
a:hover {
  text-decoration: none !important;
}




.nav-category .nav-item .nav-link {
  align-items: center;
  color: #001e2b !important;
  display: flex;
  justify-content: space-between;
  padding: .5rem 0
}

.nav-category .nav-item .nav-link:hover {
  color: var(--orange_color_dg) !important
}

/* FOOOTER */
.footer {
  background-color: var(--fc-light);
}

.py-4s {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.mb-4s {
  margin-bottom: 1rem !important;
}

.my-8 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}

.footer .nav .nav-item .nav-link {
  color: var(--fc-gray-600) !important;
  padding: 0;
}

.signInPage {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.signInPage .shape-bottom {
  position: absolute;
  top: auto;
  bottom: -6px;
  left: 0;
  right: 0;
  z-index: 1;
}

.signInPage .box {
  width: 400px;
  height: auto;
  background: rgba(190, 190, 190, 0.333);
  margin: auto;
  position: relative;
  z-index: 100;
  border-radius: 10px !important;
}

.signInPage .box h2 {
  font-size: 25px;
  font-weight: 600;
}

.signInPage .box form {
  padding: 20px 30px;
}

.signInPage .box .form-group {
  margin-bottom: 15px;
}

.signInPage .box .txt {
  font-size: 14px;
  font-weight: bold !important;
}

.border-effect {
  position: relative;
  color: #337ab7 !important;
  text-decoration: none
}

.border-effect:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #102a44;
  content: "";
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.border-effect:hover {
  text-decoration: none;
  color: #000 !important
}

.border-effect:hover:after {
  width: 100%
}

.signUpPage .box {
  width: 500px;
}